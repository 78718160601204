<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <project-employee-left-panel :data-item='employee'></project-employee-left-panel>
                </div>
                
                <span>
					<hr>
				</span>

                <span>
            

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger mr-2 p-2 pl-3 pr-3' />
                
                <a href="/#/PersonelCikisVideo" target="_blank">
                    <Button label='Video' icon='pi pi-play' class='p-button-help mr-2 p-2 pl-3 pr-3' />
                </a>

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Çalışma Durumu</h3>

					<p class="text-lg">
                        İşe giriş tarihi resmi giriş tarihi ile aynı olmalıdır.<br>
                        <br>
                        <b>İşten Çıkış Tarihi:</b> İşten çıkış yapıldığında girilecek olup kaydettiğinizde muhasebeye bu bilgi ulaşır. Resmi olarak da çıkış işlemi yapılır. Kaydet işlemi yapmadan önce çıkışın kesinleştiğinden emin olunuz.<br>
                        <br>
                        Çıkış işlemi yapıldıktan sonra personelinizden istifa dilekçesi almanız durumlarında. Bu dilekçenin dosyalar bölümüne yüklendiğinden emin olunuz.<br>
                        <br>
                        <b>Departman:</b> Personelin çalıştığı bölümü seçiniz.<br>
                        <br>
					</p>

				</Sidebar>

				</span>

            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <project-employee-tab-menu :employee-id='employeeId' :project-employee-id='projectEmployeeId'></project-employee-tab-menu>

                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Departman<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='department' v-model='projectEmployee.department' :options='departmentList' optionLabel='name' option-value='name' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>

                    <div class="field grid mb-5">
                        <label class="col-12 mb-2 md:col-2 md:mb-0">İşe Giriş Tarihi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Calendar v-if='!dataIsPassive || userType == "Admin"' id='startDate' :showIcon="true" dateFormat="dd.mm.yy"  :showButtonBar="true" v-model='projectEmployee.startDate'></Calendar>
                            <span v-if='dataIsPassive && userType != "Admin"'>  {{ formatDate(projectEmployee.startDate) }} </span>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Durum</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isActive' v-model='projectEmployee.isActive' v-if='!dataIsPassive || userType == "Admin"' />
                            <span v-if='dataIsPassive && userType != "Admin"'> Kullanıcı {{ projectEmployee.passiveManagerUser?.name }} tarafından {{ formatDate(projectEmployee.passiveProcessDate) }} tarihinde pasif yapıldı. Tekrar aktif yapılamaz. </span>
                        </div>
                    </div>

                    <div class="field grid mb-5" v-if='!projectEmployee.isActive'>
                        <label class="col-12 mb-2 md:col-2 md:mb-0">İşten Çıkış Tarihi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class="col-12 md:col-10">
                            <Calendar v-if='!dataIsPassive || userType == "Admin"' id='endDate' :showIcon="true" dateFormat="dd.mm.yy"  :showButtonBar="true" v-model='projectEmployee.endDate'></Calendar>
                            <span v-if='dataIsPassive && userType != "Admin"'>  {{ formatDate(projectEmployee.endDate) }} </span>
                        </div>
                    </div>

                    <div class='field grid mb-5' v-if='!projectEmployee.isActive'>
                        <label for='passiveReason' class='col-12 mb-2 md:col-2 md:mb-0'>Pasif Sebebi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                        <div class='col-12 md:col-10'>
                            <Dropdown id='passiveReason' v-model='projectEmployee.passiveReason' :options='passiveReasonList' optionLabel='name' option-value='name' placeholder='Seçim Yapın'></Dropdown>
                        </div>
                    </div>
                    <div class='field grid mb-5'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'>Emekli mi ?</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isRetired' v-model='projectEmployee.isRetired'/>
                        </div>
                    </div>
                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='save' label='Kaydet' icon='pi pi-save' @click='save'></Button>
                        </div>
                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>


import {
    showErrorMessage,
    showSuccessMessage, showValidationMessage,
} from '../../components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import moment from 'moment';
import ProjectEmployeeLeftPanel from './projectEmployeeLeftPanel';
import ProjectEmployeeTabMenu from './projectEmployeeTabMenu';
import ProjectEmployeeService from '../../services/projectEmployeeService';
import {
    checkEmployeeSalaryCalculated,
    checkEmployeeSalaryChanged,
    getAllBanks,
    getDepartmentList,
    getEmployee,
    getProjectEmployee,

} from '../common/commonFunctions';
import {
    checkActiveProjectIdIsValid,
    getActiveProjectId,
    getUserId,
    getUserType,
    normalizeDate,
} from '../common/commonConstantFunctions';
import SmsService from '@/services/smsService';

export default {
    components: { ProjectEmployeeTabMenu, ProjectEmployeeLeftPanel, AdminToast },
    _employeeService: null,
    _projectEmployeeService: null,
    _smsService: null,

    created() {
        this._employeeService = new EmployeeService();
        this._smsService = new SmsService();
        this.employeeId = this.$route.params.employeeId;
        this.projectEmployeeId = this.$route.params.projectEmployeeId;
        this.projectId = getActiveProjectId();
        this._projectEmployeeService = new ProjectEmployeeService();
        this.userType = getUserType();
    },
    mounted: async function() {
        this.employee = await getEmployee(this.employeeId);
        this.bankList = await getAllBanks();
        this.departmentList = await getDepartmentList();
        this.projectEmployee = await getProjectEmployee(this.projectEmployeeId);
        checkActiveProjectIdIsValid(this, this.projectEmployee.projectId);
        if(!this.projectEmployee.isActive)
        {
            this.dataIsPassive = true;
        }
        else
        {
            this.dataIsPassive = false;
        }

    },
    data() {

        return {
            employeeId:0,
            projectEmployeeId:0,
            dataIsPassive:false,
            projectEmployee: {
                projectId: "",
                employeeId: "",
                startDate: "",
                endDate: "",
                isActive: "",
                officialSalary: "",
                unOfficialSalary: "",
                insertDate: "",
                iban: "",
                department: "",
                passiveReason: "",
                comments: "",
                insuranceDeposit: "",
                bankName: "",
            },
            userType:"",
            employee: {},
            departmentList:[],
            passiveReasonList:[
                {
                    name:"İstifa"
                },
                {
                    name:"İşten Çıkarılma"
                },
                {
                    name:"Askerlik"
                },
                {
                    name:"Doğum"
                },
                {
                    name:"Proje Değişikliği"
                }
            ],
            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {
            if (await this.validateForm()) {

                if(this.projectEmployee.isActive == true)
                {
                    this.projectEmployee.passiveReason = '';
                    this.projectEmployee.endDate = null;
                    this.projectEmployee.passiveProcessDate = null;
                    this.projectEmployee.passiveManagerUserId = null;
                }
                else
                {
                    this.projectEmployee.passiveProcessDate =   moment().add(3, 'hour').toDate();
                    this.projectEmployee.passiveManagerUserId = getUserId();
                }
                this.projectEmployee.startDate = normalizeDate(this.projectEmployee.startDate);
                if(this.projectEmployee.endDate != null)
                {
                    this.projectEmployee.endDate = normalizeDate(this.projectEmployee.endDate);
                }
                const updateResponse = await this._projectEmployeeService.updateProjectEmployeeWorkStatus(this.projectEmployee.id, this.projectEmployee);
                if (updateResponse.isSuccess) {
                    if(this.projectEmployee.isActive == false)
                    {
                        await this._smsService.sendEmployeeRemoveMessage(this.projectEmployeeId);
                    }
                    showSuccessMessage(this, 'Çalışan güncelleme başarılı');
                } else {
                    showErrorMessage(this, 'Çalışan güncellemede sorun ile karşılaşıldı');
                }
            }

        },
        async validateForm() {

            if (this.userType != "Admin" && this.dataIsPassive) {
                showValidationMessage(this, "Kullanıcı pasif yapıldığı için işlem yapılamaz")
                return false;
            }
            if (!checkEmployeeSalaryChanged(this, this.projectEmployee)) {
                return false;
            }
            if (this.projectEmployee.endDate != null && await checkEmployeeSalaryCalculated(this, this.projectEmployee.endDate, this.projectEmployeeId) == true) {
                return false;
            }
            if (this.projectEmployee.startDate == null || this.projectEmployee.startDate == '') {
                showValidationMessage(this, "İşe Giriş tarihi boş bırakılamaz")
                return false;
            }
            if (this.projectEmployee.isActive == false && (this.projectEmployee.endDate == null || this.projectEmployee.endDate == '')) {
                showValidationMessage(this, "Pasif yapılan kullanıcı için işten çıkış tarihi girilmelidir.")
                return false;
            }
            if (this.projectEmployee.isActive == false && (this.projectEmployee.passiveReason == null || this.projectEmployee.passiveReason == '')) {
                showValidationMessage(this, "Pasif yapılan kullanıcı için sebep girilmelidir.")
                return false;
            }

            return true;
        },

        formatDate(value) {
            let newDate = moment(value);
            return newDate.format('DD.MM.YYYY');
        },
    },
};

</script>


<style scoped>

</style>
